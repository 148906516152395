import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Title from "components/Title";
import LoadingAnimation from "components/LoadingAnimation";

export default function Custom404(){
	return (
		<>
			<Title title="Sorry page not found"/>
			<CenterBlock>
				<Custom404Wrapper>
					<LoadingAnimation>404<br/>Not Found</LoadingAnimation>
				</Custom404Wrapper>
			</CenterBlock>
		</>
	)
}

const Custom404Wrapper = styled.section`
	margin-bottom: 40px;
	margin-top: 20px;
	background: #ffffff;
	padding: 40px;
	height: 50vh;
	border-radius: 20px;
`
